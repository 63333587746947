<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <el-button
        class="v-primary"
        type="primary"
        @click="addGoods"
        >发布商品</el-button
      >
      <div>
        <el-select v-model="value" placeholder="请选择" style="width: 240px">
          <el-option value="" label="全部" class="cbcc">全部</el-option>
          <el-option
            v-for="item in optionList"
            :key="item.productsTypeId"
            :label="item.productsTypeName"
            :value="item.productsTypeId"
          >
          </el-option>
        </el-select>
        <el-button
          class="v-primary ml10"
          type="primary"
          @click="queryAppointmentPage"
          >查询</el-button
        >
      </div>
    </div>

    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="productsId" width="100" align="center" label="产品ID" />
        <el-table-column
          prop="productsName"
          align="center"
          label="产品名称"
          show-overflow-tooltip
        />
        <el-table-column prop="productsTypeName" align="center" label="分类" />
        <el-table-column prop="price" align="center" label="价格" />
        <el-table-column prop="createTime" align="center" label="发布时间">
          <template slot-scope="scope"
            ><span>{{ setDataTimeSec(scope.row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <span
              class="mr10 hover_pointer cblue"
              @click="editorGoods(scope.row.productsId)"
              >编辑</span
            >
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="delGoodsInfo(scope.row.productsId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定删除该商品？"
            >
              <el-button slot="reference" class="hover_pointer corange" style="color: #fd634e" type="text"
                >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <span
              class="el-icon-upload2 mr10 hover_pointer"
              style="font-size: 20px"
              @click="goodsSort('up', scope.row.productsId)"
            ></span>
            <span
              class="el-icon-download hover_pointer"
              style="font-size: 20px"
              @click="goodsSort('down', scope.row.productsId)"
            ></span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  fetchAppointmentGoodsList,
  fetchProductsTypeList,
  alterAppointmentGoodsSort,
  delProducts
} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
export default {
  name: "appointmentList",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
        type: Boolean,
        default: false,
    },
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      value: "",
      tableData: [],
      optionList: "", //全部分类
      loading: "",
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
    };
  },

  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  mounted() {
    this.getTableData();
    this.getTypeList();
  },
  methods: {
    //请求预约商品列表数据
    getTableData() {
      this.loading = true;
      let data = {
        ignore: true,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        appointmentTime: this.queryDate,
        productsTypeId: this.value,
        associationId: localStorage.getItem("associationId")
      };
      fetchAppointmentGoodsList(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list || "";
          this.total = res.data.pageInfo.total;
          this.tableData.forEach((v) => {
            v.price = JSON.parse(v.price);
            console.log(typeof(v.price))
            if(typeof(v.price)=="object"){
              v.price = v.price[0] + '~' + v.price[1]
            }
            v.appointmentTime = `${getDataTimeSec(
              v.startTime
            )} - ${getDataTimeSec(v.endTime)}`;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    //查询分类
    getTypeList() {
      fetchProductsTypeList({ ignore: true,associationId: localStorage.getItem("associationId") })
        .then((res) => {
          this.optionList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      this.loading = true;
      this.getTableData();
    },
    //修改排序
    goodsSort(type, id) {
      if(this.userInfo.roleId || this.jurisdiction){
        let data = "";
        if (type == "up") {
          data = {
            productsId: id,
            type: 1,
            associationId: localStorage.getItem("associationId")
          };
        } else {
          data = {
            productsId: id,
            type: 2,
            associationId: localStorage.getItem("associationId")
          };
        }
        alterAppointmentGoodsSort(data)
        .then((res) => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.loading = true;
          this.getTableData();
        })
        .catch((err) => {
          console.log(err);
        });
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }
    },
    editorGoods(id){
      if(this.userInfo.roleId || this.jurisdiction){
        this.$router.push(`/launchProducts?id=${id}`)
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }
    },
    addGoods(){
      if(this.userInfo.roleId || this.jurisdiction){
        this.$router.push(`/launchProductsSH`)
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }
      // if(!this.userInfo.roleId){
      //   this.$message({
      //     type: "warning",
      //     message: "无操作权限",
      //   });
      //   return
      // }

    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.loading = true;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.loading = true;
      this.getTableData();
    },
    async delGoodsInfo(id){
      // if(!this.userInfo.roleId){
      //   this.$message({
      //     type: "warning",
      //     message: "无操作权限",
      //   });
      //   return
      // }
      if(this.userInfo.roleId || this.jurisdiction){
        await delProducts({productsId:id,associationId: localStorage.getItem("associationId")});
        this.getTableData();
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }

    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>
